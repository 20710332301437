import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

class AssignVoucher {
  constructor(email, campaign) {
    this.email = email;
    this.campaign = campaign;
    this.data = null;
  }
  async init() {
    if (this.campaign === null) {
      await this.getVoucherByEmail();
    } else {
      await this.getVoucherByEmailAndCampaignCode();
    }
  }

  async getVoucherByEmail() {
    const {
      data: { getVoucher_by_email: results },
    } = await API.graphql({
      query: queries.getVoucher_by_email,
      variables: { email: this.email },
      authMode: "API_KEY",
    });
    this.data = results === null ? false : results;
  }

  async getVoucherByEmailAndCampaignCode() {
    let { code } = this.campaign;
    code = code.toLowerCase().trim();
    try {
      const {
        data: { getVoucher_by_email: results },
      } = await API.graphql({
        query: queries.getVoucher_by_email,
        variables: { email: this.email, code: code },
        authMode: "API_KEY",
      });
      this.data = results;
    } catch (e) {
      // console.log(e, "error");
      throw new Error("Sorry, email not recognised, please try again.");
    }
  }

  doesVoucherExist() {
    return this.data === null ? false : true;
  }

  voucherLimitExceeded() {
    const { voucher_count, voucher_limit } = this.campaign;
    return voucher_count > voucher_limit;
  }

  checkEmailIsValid() {
    const { domain } = this.campaign;
    if (!domain) {
      return true;
    }
    const emailDomain = this.email.split("@").pop();
    return domain
      .toLowerCase()
      .trim()
      .includes(emailDomain.toLowerCase().trim());
  }

  async assignVoucher() {
    let { code } = this.campaign;
    code = code.toLowerCase().trim();
    const { employee_number } = this.campaign;
    const { post_code } = this.campaign;
    const { voucher_count } = this.campaign;
    try {
      const {
        data: { process_voucher },
      } = await API.graphql({
        query: mutations.process_voucher,
        variables: {
          email: this.email,
          code: code,
          voucher_limit: voucher_count,
          sender: "flu@healthyperformance.co.uk",
          client: "dearspharmacy",
          employee_number: employee_number,
          post_code: post_code,
        },
        authMode: "API_KEY",
      });
      return process_voucher;
    } catch (e) {
      console.log(e, "error");
      throw new Error(e.errors[0].message);
    }
  }

  getVoucher() {
    return this.data;
  }
}

export default async function voucherFactory(email, campaign, factoryType) {
  const voucher = new AssignVoucher(email, campaign);
  await voucher.init();

  switch (factoryType) {
    case "checkemail": {
      return voucher.data;
    }
    case "manage": {
      if (voucher.data === null) {
        throw new Error("Sorry, email not recognised, please try again.");
      }
      let email = voucher.data.email;
      // email = email.replace(/(?<=.)(.+?)(?=.@)/gi, '*'.repeat(email.split('@')[0].length - 2));
      let emailSplit = email.split("@");
      if (emailSplit.length > 1) {
        let start = emailSplit.shift();
        let end = emailSplit.join("@");
        if (start.length >= 3) {
          let firstChar = start.charAt(0);
          let lastChar = start.charAt(start.length - 1);
          let charsToFill = start.length - 2;
          let newStart = firstChar + "*".repeat(charsToFill) + lastChar;
          email = newStart + "@" + end;
        }
      }
      const maskedEmail = {
        masked_email: email,
      };
      Object.assign(voucher.data, maskedEmail);
      return voucher.data;
    }

    default: {
      // Does email with access code already exist
      const isVoucher = voucher.doesVoucherExist();
      if (isVoucher) {
        throw new Error(
          'Sorry, this email address is already registered. Please go to Manage your existing voucher and select "Resend" to receive your voucher.'
        );
      }

      // Is email valid in email domain whitelist supplied in campaignAccessCache
      if (!voucher.checkEmailIsValid()) {
        throw new Error("Sorry, incorrect email address. Please try again.");
      }

      // Find voucher and assign it to user
      const assignedVoucher = await voucher.assignVoucher();
      return assignedVoucher;
    }
  }
}
