import Vue from "vue";
import VueRouter from "vue-router";
import Home22 from "../views/Home22.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home22,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("../views/Maintenance.vue"),
  },
  {
    path: "/manage",
    name: "Manage",
    component: () => import("../views/Manage.vue"),
  },
  {
    path: "/register/campaigncode",
    name: "CampaignCode",
    component: () => import("../views/RegisterCampaignCode.vue"),
  },
  {
    path: "/register/employee/:code",
    name: "Employee",
    component: () => import("../views/RegisterEmployee.vue"),
  },
  {
    path: "/register/pre/:code",
    name: "PreRegister",
    component: () => import("../views/PreRegister.vue"),
  },
  {
    path: "/register/postcode/:code",
    name: "Postcode",
    component: () => import("../views/RegisterPostcode.vue"),
  },
  {
    path: "/register/email/:code",
    name: "Email",
    component: () => import("../views/RegisterEmail.vue"),
  },
  {
    path: "/register/complete/:code",
    name: "Complete",
    component: () => import("../views/RegisterComplete.vue"),
  },
  {
    path: "/find",
    name: "Find",
    component: () => import("../views/Find.vue"),
  },
  {
    path: "/refertogp",
    name: "Refertogp",
    component: () => import("../views/ReferToGP.vue"),
  },
  // {
  //   path: "/oversixtyfive",
  //   name: "Oversixtyfive",
  //   component: () => import("../views/OverSixtyFive.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "Maintenance" &&
    to.name !== "Find" &&
    process.env.VUE_APP_MAINTENTANCE_MODE === "true"
  )
    next({ name: "Maintenance" });
  else next()
});

export default router;
