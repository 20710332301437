<template>
  <div id="app">
    <div class="d-flex justify-content-center mt-5 mb-5">
      <div>
        <router-link to="/">
          <img src="/images/dears-pharmacy-logo-white.svg" style="width:200px;" />
          <!-- <object id="svg1" data="/images/dears-pharmacy-logo-white.svg" type="image/svg+xml"></object> -->
        </router-link>
      </div>
      <div class="px-3">
        <router-link to="/">
          <img
            src="/images/fluicon-logo-lg.png"
            class="pl-3 img-fluid"
            style="max-height: 69px"
          />
        </router-link>
      </div>
    </div>

    <!-- <div class="d-md-none d-sm-flex d-flex justify-content-center mt-1 mb-2">
      <div class="">
        <div class="my-3">
          <a href="#">
           <img src="/images/dears-pharmacy-logo-white.svg" style="width:200px;" />
          </a>
        </div>
        <div class="text-center">
          <a href="#">
            <img
              src="/images/fluicon-logo-lg.png"
              class="pl-3 img-fluid"
              style="max-height: 69px"
            />
          </a>
        </div>
      </div>
    </div> -->
    <div class="container">
      <router-view />
    </div>
    <div class="container">
      <footer class="mt-5">
        <div>
          <div class="row">
            <div class="col">
              <div class="text-center documents">
                Read our
                <a href="/downloads/privacy-policy.pdf" target="_blank"
                  >privacy policy</a
                >
                and our
                <a href="downloads/terms-of-usage.pdf" target="_blank"
                  >terms of use</a
                >.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="text-center">
                <a href="mailto:flu@healthyperformance.co.uk"
                  >flu@healthyperformance.co.uk</a
                >
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "App",
  methods: {},
};
</script>
